<!-- add by Liwh time:2024-09-01 description:轮播图选择关联产品页面 -->

<template>
  <el-dialog title="选择商品" :model-value="modelValue" @update:model-value="updateVisible" class="roll-dialog" >
    <el-table v-loading="loading" element-loading-text="加载中，请稍后..."
              :data="management"
              key="slot" @row-click="singleElection"
              ref="tableRef"
              style="text-align: center" >
      <el-table-column label="" width="40vw">
        <template #default="scope">
          <el-radio class="radio" v-model="templateSelection" :label="scope.row.id">&nbsp;</el-radio>
        </template>
      </el-table-column>
      <el-table-column label="序号" width="70vw">
        <template v-slot="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="200vw">
      </el-table-column>
      <el-table-column prop="price" label="价格" width="80vw">
      </el-table-column>
      <el-table-column prop="stock" label="库存" width="80vw">
      </el-table-column>
      <el-table-column prop="categoriesName" label="所属分类" width="110vw">
      </el-table-column>
      <el-table-column v-if="manage === '0'" prop="storeName" label="所属门店" width="100vw">
      </el-table-column>
      <el-table-column prop="coverUrl" label="图片" width="100vw">
        <template #default="scope">
          <div style="width: 70px;height: 50px;display: flex; align-items: center;">
            <el-image fit="fill" lazy
                      :src="`https://www.jingtengyiliao.com/api/common/download?name=${scope.row.coverUrl}`"
                      error>
              <el-icon>
                <icon-picture/>
              </el-icon>
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="详情" width="150vw" flexd="right">
        <template #default="scope">
          <el-button type="primary" @click="detail(scope.row.id,scope.row.id)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                   @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                   :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                   layout="total, sizes, prev, pager, next, jumper" :total="tatol">
    </el-pagination>
    <template #footer>
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {reactive, ref, toRaw, defineProps, watch} from 'vue';
import {useRouter} from 'vue-router';
import instance from '../network/storester.js'
import {ElMessage, ElMessageBox} from 'element-plus'
import {Picture as IconPicture} from '@element-plus/icons-vue'

const templateSelection = ref('');
const images = ref([])
const image = ref([])
const tatol = ref(0);
const management = ref([]);
const managem = ref(null);
const loading = ref(true)
const router = useRouter();
const input = ref('');
const manage = localStorage.getItem('permissionsStatus');
const value2 = ref(false)
const page = reactive({pageNum: 1, pageSize: 10});
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
});

const emit = defineEmits(['confirm', 'update:modelValue']);

const selectedResult = ref('');




const handleConfirm = () => {
  if (!selectedResult.value) {
    ElMessage.error('请选择关联商品');
    return;
  }
  console.log(selectedResult.value)
  emit('confirm', selectedResult.value);
  emit('update:modelValue', false);
};

const handleCancel = () => {
  emit('update:modelValue', false);
};

const updateVisible = (value) => {
  emit('update:modelValue', value);
};

watch(() => props.modelValue, (newVal) => {
  if (!newVal) {
    selectedResult.value = ''; // 重置选择
  }
});

const singleElection = (row) =>{
  selectedResult.value = row;
};



const handleSizeChangese = (val) => {
  page.pageSize = val;
  console.log(val);
  // if (value2.value === true) {
  //     offshelf()
  //     return
  // }
  loading.value = true;
  siftsea();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
  page.pageNum = val;
  console.log(val);
  // if (value2.value === true) {
  //     offshelf()
  //     return
  // }
  loading.value = true;
  siftsea();
};
//设置分类

const siftsea = () => {
  if (localStorage.getItem('permissionsStatus') === '0') {
    value2.value = false
    input.value = ''
    instance.get(`/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
          managem.value = response.data.data.allProductInfo;
          management.value = toRaw(managem.value);
          tatol.value = response.data.data.totalCount
          console.log(management.value[0].storeName);

          loading.value = false;
          console.log(images.value);
          console.log(image.value);
        }).catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('./')
    })

  } else {
    instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
          loading.value = true;
          value2.value = false
          input.value = ''
          managem.value = response.data.data;
          management.value = toRaw(managem.value);
          instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=1&pageSize=${10000}`)
              .then(response => {
                tatol.value = response.data.data.length
              })
          loading.value = false;
          console.log(management.value);
        }).catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('./')
    })
  }
}
if (localStorage.getItem('permissionsStatus') === '0') {
  instance.get(`/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
      .then(response => {
        console.log(response);
        managem.value = response.data.data.allProductInfo;
        management.value = toRaw(managem.value);
        tatol.value = response.data.data.totalCount
        console.log(management.value)
        loading.value = false;
      }).catch(error => {
    console.log(error);
    ElMessage({
      showClose: true,
      message: '请登录',
      type: 'error',
    })
    router.push('./')
  })
} else {
  instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
      .then(response => {
        console.log(response);
        managem.value = response.data.data;
        console.log(managem.value);
        instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=1&pageSize=${10000}`)
            .then(response => {
              tatol.value = response.data.data.length
            })
        management.value = toRaw(managem.value);
        loading.value = false;
        console.log(management.value);
      }).catch(error => {
    console.log(error);
    ElMessage({
      showClose: true,
      message: '请登录',
      type: 'error',
    })
    router.push('./')
  })
}
const detail = (id,storeId) => {
  console.log(id);
  router.push({
    path: '/productdetails',
    query: {
      msg: id,
      storeId:storeId,
    }
  })
};


</script>
<style>
.el-dialog {
  width: 82%;
}
.roll-dialog .el-dialog__body {
  padding: 3px 30px;
  overflow-y: auto;
  height: calc(100vh - 140px);
}
</style>