<!-- 管理商品 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="shang">
                <div class="comm">
                    <div class="fen">
                        <el-input v-model="input" placeholder="请输入商品名称" @keyup.enter="query"
                            class="OrderNumber"></el-input> <el-button type="primary" class="queryse" @click="query"
                            style="margin-top:-0.7vh; margin-left: -3vw;">查询</el-button>
                        <el-switch class="switchs" v-model="value2" size="large" active-text="下架" inactive-text="全部"
                            @change="offshelf" />
                        <!-- <el-select v-model="value" v-if="manage !== '0'" clearable placeholder="请选择商品的分类"
                            @change="addsd">
                            <el-option v-for="item in classifys" :key="item.value" :label="item.label"
                                :value="item.name" />
                        </el-select> -->
                        <el-button type="primary" @click="addsa" class="addproducts">添加商品</el-button>

                    </div>
                    <div class="alluserbody">
                        <el-table v-loading="loading" element-loading-text="加载中，请稍后..."
                            style="width: 100%; margin-left: 10%;" height="85%" :data="management" class="orders">
                            <el-table-column prop="id" label="商品ID" width="40vw">
                            </el-table-column>
                            <el-table-column prop="storeSort" label="门店排序" width="40vw">
                            </el-table-column>
                            <el-table-column prop="name" label="名称" width="100vw">
                            </el-table-column>
                            <el-table-column prop="price" label="价格" width="50vw">
                            </el-table-column>
                            <el-table-column prop="stock" label="库存" width="50vw">
                            </el-table-column>
                            <el-table-column prop="categoriesName" label="所属分类" width="100vw">
                            </el-table-column>
                            <el-table-column v-if="manage === '0'" prop="storeName" label="所属门店" width="100vw">
                            </el-table-column>
                            <el-table-column prop="coverUrl" label="图片" width="80vw">
                                <template #default="scope">
                                    <div style="width: 50px; height: 50px; display: flex; align-items: center;"> 
                                        <el-image v-if="scope.row.coverUrl" :src="getImageUrl(scope.row.coverUrl)" fit="contain" :key="scope.row.id" />  
                                    </div>  
                                </template>
                            </el-table-column>
                            <el-table-column label="修改" width="250vw">
                                <template #default="scope" v-if="prop === 'id'"></template>
                                <template #default="scope" v-if="prop === 'name'"></template>
                                <template #default="scope" v-if="prop === 'price'"></template>
                                <template #default="scope" v-if="prop === 'coverUrl'"></template>
                                <template #default="scope">
                                    <el-button type="primary" v-if="scope.row.productStatus === 1"
                                        @click="goToUpdaStore(scope.row.id, scope.row.name, scope.row.price, scope.row.coverUrl, scope.row.storeName, scope.row.categoriesName, scope.row.storeId, scope.row.commission, scope.row.stock, scope.row.productStatus, scope.row.systemType)">编辑</el-button>
                                    <el-button type="danger" @click="open(scope.row.id)">删除商品</el-button>
                                    <el-button type="primary" v-if="scope.row.productStatus === 0"
                                        @click="shelves(scope.row.id)">上架</el-button>
                                    <el-button type="primary" v-else @click="takedown(scope.row.id)">下架</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="" label="详情" width="250vw" flexd="right">
                                <template #default="scope">
                                    <el-button type="primary" @click="detail(scope.row.id)">详情</el-button>
                                    <el-button type="danger" @click="DeleteDetails(scope.row.id)">删除详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="alluserfoots">
                        <div class="demo-pagination-blocks">
                            <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </el-container>
    </el-container>
</template>
<script setup>
import { ref, reactive, onMounted, onBeforeUnmount,toRaw  } from 'vue';  
import { useRouter } from 'vue-router';  
// import axios from 'axios';  
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus';

const abortControllers = ref({});  
const total = ref(0);  
const managem = ref('')
const management = ref([]);  
const loading = ref(true);  
const router = useRouter();  
const page = reactive({ pageNum: 1, pageSize: 10 });  
const imageAbortControllers = ref({}); 
const input = ref('');
const value2 = ref(false)

// 请求图片的 URL 生成方法  
const getImageUrl = (coverUrl, signal) => {
    const baseUrl = `https://www.jingtengyiliao.com/api/common/download?name=${coverUrl}`;
    // 假设这里需要根据是否有信号来调整URL或者添加相关请求头之类的（实际可能需要和后端协商具体方式），目前简单返回原URL示例
    return baseUrl;
};
// 页面大小变化处理  
const handleSizeChange = (val) => {  
  page.pageSize = val;  
  siftsea(); // 重新加载数据  
};  

// 当前页面变化处理  
const handleCurrentChange = (val) => {  
  page.pageNum = val;  
  siftsea(); // 重新加载数据  
};  

onMounted(() => {  
    siftsea(); // 初始化加载数据  
});  
const siftsea = () => {
    loading.value = true;
    const apiUrl = localStorage.getItem('permissionsStatus') === '0'
       ? `/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`
        : `/api/tproduct/selectTproductByStoreId?storeId=${storeId}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`;

    instance.get(apiUrl)
      .then(response => {
            management.value = response.data.data.allProductInfo || [];
            total.value = response.data.data.totalCount || 0;
            loading.value = false;
          
        })
      .catch(error => {
            if (instance.isCancel(error)) {
                console.log('请求已取消');
                return;
            }
            console.error(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            });
            // router.push('./');
        })
    
};

const offshelf = () => {
    console.log(value2.value);
    loading.value = true;
    if (value2.value === true) {
        if (localStorage.getItem('permissionsStatus') === '0') {
            instance.get(`/api/tproduct/takeDown?pageNum=${1}&pageSize=${100000}`)
                .then(response => {
                    managem.value = response.data.data;
                    console.log(managem.value);
                    management.value = toRaw(managem.value);
                    console.log(management.value);
                    console.log(images.value);
                    loading.value = false;
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        } else {
            instance.get(`/api/tproduct/storeTakeDown?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${1}&pageSize=${10000}`)
                .then(response => {
                    managem.value = response.data.data;
                    console.log(managem.value);
                    management.value = toRaw(managem.value);
                    console.log(images.value);
                    console.log(management.value);
                    loading.value = false;
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    } else {
        if (localStorage.getItem('permissionsStatus') === '0') {
            instance.get(`/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    managem.value = response.data.data.allProductInfo;
                    console.log(managem.value);
                    tatol.value = response.data.data.totalCount
                    management.value = toRaw(managem.value);
                    loading.value = false;
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        } else {
            instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    managem.value = response.data.data;
                    console.log(managem.value);
                    management.value = toRaw(managem.value)
                    instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${1}&pageSize=${10000}`)
                        .then(response => {
                            tatol.value = response.data.data.length
                        })
                    loading.value = false;
                    console.log(management.value);
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    }
}


const query = () => {
    value2.value = false
    console.log(value2.value);
    if (localStorage.getItem('permissionsStatus') === '0') {
        loading.value = true;
        console.log(input.value);
        console.log(input.value);
        if (input.value === '') {
            siftsea()
        } else {
            instance.get(`/api/tproduct/search?name=${input.value}`)
                .then(response => {
                    console.log(response.data);
                    managem.value = response.data;
                    management.value = toRaw(managem.value);
                    loading.value = false;
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '没有该商品',
                        type: 'error',
                    })
                    // router.push('./')
                })
        }
    } else {
        if (input.value === '') {
            siftsea()
        } else {
            instance.get(`/api/tproduct/store/search/${localStorage.getItem('sysStoreId')}?name=${input.value}`)
                .then(responses => {
                    console.log(responses.data.data);
                    managem.value = responses.data.data;
                    management.value = toRaw(managem.value)
                    console.log(management.value);
                    loading.value = false;
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    }

};

const goToUpdaStore = (id, name, price, images, storeName, categoriesName, storeId, commission, stock, productStatus, systemType) => {
    console.log(id);
    console.log(name);
    console.log(price);
    console.log(images);
    console.log(storeName);
    console.log(storeId);
    if (localStorage.getItem('permissionsStatus') === '0') {
        router.push({
            path: '/editproduct',
            // name: 'home',
            query: {
                msg: id,
                name: name,
                price: price,
                images: images,
                storeName: storeName,
                categoriesName: categoriesName,
                storeId: storeId,
                commission: commission,
                stock: stock,
                productStatus: productStatus,
                systemType: systemType
            }
        })
    } else {
        router.push({
            path: '/editproduct',
            // name: 'home',
            query: {
                msg: id,
                name: name,
                price: price,
                images: images,
                storeName: storeName,
                categoriesName: categoriesName,
                storeId: localStorage.getItem('sysStoreId'),
                commission: commission,
                stock: stock,
                productStatus: productStatus,
            }
        })
    }

}
const detail = (id) => {
    console.log(id);
    router.push({
        path: '/productdetails',
        query: {
            msg: id
        }
    })
};
const takedown = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要下架该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.put(`/api/tproduct/status/${id}`)
                .then(response => {
                    loading.value = true
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: `下架成功`,
                    })
                    siftsea();
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消下架',
            })
        })
}
const shelves = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要上架该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.put(`/api/tproduct/status/${id}`)
                .then(response => {
                    loading.value = true
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: `上架成功`,
                    })
                    siftsea();
                })
            console.log('上架成功');
            siftsea();

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消上架',
            })
        })

}

const open = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要删除该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/tproduct/forcedDeletion/${id}`)
                .then(response => {
                    console.log(1);
                    console.log(response);
                    loading.value = true
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    siftsea();
                }).catch((error) => {
                    console.log(error);
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除',
                    })
                })
        })
}
const DeleteDetails = (id) => {
    console.log(id);

    ElMessageBox.confirm(
        '您确定要删除该商品详情吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/productVideo/${id}`)
            instance.delete(`/api/details/${id}`)
                .then(response => {
                    console.log(response);
                    loading.value = true
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    siftsea();
                })
                .catch((error) => {
                    console.log(error);
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
const addsa = () => {
    router.push('../AllProducts')
}



</script>
<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 11vw;
    width: 85vw;
    height: 90vh;
}

.OrderNumber {
    /* top: -46.5vh !important; */
    left: -3vw !important;
}

.orders {
    position: fixed;
    margin-top: 15vh;
    left: 8vw;
}

.fen .el-select {
    position: fixed;
    top: 14vh;
    left: 28vw;
    width: 14vw;
    font-size: 12px;
}

.comm .el-table {
    position: fixed;
    top: 6%;
    left: 1%;
}

.comm .el-table tr.el-table__row {
    height: 65px !important;
    font-size: 12px !important;
}

.comm .el-button {
    /* height: 24px !important; */
    font-size: 10px !important;
}

.shang {
    /* width: 85%; */
    font-size: 12px !important;
}

.el-table {
    width: 100%;
}

.el-table__header-wrapper table,
.el-table__body-wrapper table {
    width: 100% !important;
}

.el-table__body,
.el-table__footer,
.el-table__header {
    table-layout: auto;
}

.fen .addproduct {
    position: fixed;
    top: 14vh;
    right: 20vw;
}

.fen .addproducts {
    position: fixed;
    top: 14vh;
    right: 15vw;
    width: 10vw;
}

.fen {
    position: fixed;
    top: 13vh;
    z-index: 1;
}

/* .fen .el-switch {
    top: -46.5vh !important;
    left: -5vw !important;
} */

.fen .el-input {
    width: 15vw;
}

.fen .switchs {
    left: 17vw;
}

.alluserfoots .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    top: 94vh;
    height: 10vh;
    left: 30vw;
    /* background-color: red; */
}
</style>